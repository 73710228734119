import React, { Component } from "react";
import Header from "./Header";

interface Props {
  title: string;
  image: string;
  titleBlue?: string;
  description?: string;
  addDescription?: string;
}

export default class Hero extends Component<Props> {
  render() {
    const textClassName =
      "mt-3 text-base text-justify text-gray-500 sm:mt-5 sm:text-md sm:max-w-xl sm:mx-auto md:mt-5 lg:mx-0";
    return (
      <div className="relative bg-white overflow-hidden">
        <div className="max-w-7xl mx-auto">
          <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-3xl lg:w-full lg:pb-28 xl:pb-30">
            <svg
              className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
              fill="currentColor"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              aria-hidden="true"
            >
              <polygon points="50,0 100,0 50,100 0,100" />
            </svg>
            <Header />
            <main className="mx-auto mt-4 max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
              <div className="sm:text-center lg:text-left">
                <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl">
                  <span className="block xl:inline">
                    {this.props.title}{" "}
                    {this.props.titleBlue && (
                      <span className="block text-blue-600 xl:inline">
                        {this.props.titleBlue}
                      </span>
                    )}
                  </span>
                </h1>
                <p className={textClassName}>{this.props.description}</p>
                {this.props.addDescription && (
                  <p className={textClassName}>{this.props.addDescription}</p>
                )}
              </div>
            </main>
          </div>
        </div>
        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <img
            className="h-72 w-full object-cover sm:h-80 md:h-96 lg:w-full lg:h-full"
            src={this.props.image}
            alt=""
          />
        </div>
      </div>
    );
  }
}
