import { Popover } from "@headlessui/react";
import React, { Component } from "react";
import Favicon from "../assets/logo.png";

const navigation = [
  { name: "About Us", href: "/about" },
  { name: "Members", href: "/members" },
  { name: "APEC App Challenges", href: "/apec-challenges" },
  { name: "Contact Us", href: "#contact" },
];

export default class Header extends Component {
  renderLink = (type: string) => {
    let className = "";
    const linkView: any[] = [];
    if (type === "desktop") {
      className = "font-medium text-gray-500 hover:text-blue-600";
    } else {
      className =
        "block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50";
    }

    navigation.map((item) => {
      if (item.href.includes("#")) {
        linkView.push(
          <a href={item.href} key={item.name} className={className}>
            {item.name}
          </a>
        );
      } else {
        linkView.push(
          <div key={item.name} className={className}>
            {item.name}
          </div>
        );
      }
      return null;
    });

    return linkView;
  };

  render() {
    return (
      <Popover>
        <div className="relative pt-6 px-4 sm:px-6 lg:px-8">
          <nav
            className="relative flex items-center justify-between sm:h-10 md:justify-start"
            aria-label="Global"
          >
            <div className="flex items-center flex-grow flex-shrink-0 md:flex-grow-0">
              <div className="flex items-center justify-between w-full md:w-auto">
                <img className="h-10 w-auto sm:h-11" src={Favicon} alt="" />
              </div>
            </div>
          </nav>
        </div>
      </Popover>
    );
  }
}
