import {
  CodeBracketIcon,
  CogIcon,
  ComputerDesktopIcon,
  HeartIcon,
} from "@heroicons/react/24/outline";
import React, { Component } from "react";
import HomeAsia from "../assets/bg_projects.jpg";

const transferFeatures = [
  {
    id: 1,
    name: "Beginner",
    description:
      "The course for beginners will be 2-hour game-based that provides learners with basic concepts of coding",
    icon: HeartIcon,
  },
  {
    id: 2,
    name: "Pre-intermediate",
    description:
      "The pre-intermediate level will be 5-hour programming 101 with JavaScript and Python languages",
    icon: CodeBracketIcon,
  },
  {
    id: 3,
    name: "Intermediate",
    description:
      "The intermediate level will be 5-hour computer science 101 focusing on web technology, accessibility guidelines, and inclusive design.",
    icon: ComputerDesktopIcon,
  },
  {
    id: 4,
    name: "Advanced",
    description:
      "The advanced level will be 10-hour on the internet deep dive, Git version control system, advanced web technology, JavaScript for web, network engineering, and advanced programming. Students from advanced level will be selected for 29-hour web full-stack bootcamp, including HTML, CSS, MySQL, JavaScript frameworks (Vue.js), and Node.js with workshop and project-based mentoring. Students are required to spend time self-studying around 3 times the number of hours spent for e-learning lessons, which depends on the learning pace of each student.",
    icon: CogIcon,
  },
];

export default class Projects extends Component {
  render() {
    return (
      <div className="py-16 bg-white overflow-hidden lg:py-24">
        <div className="relative max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
          <svg
            className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
            width={404}
            height={784}
            fill="none"
            viewBox="0 0 404 784"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={784}
              fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
            />
          </svg>

          <div className="relative mt-6 lg:mt-12 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div className="relative">
              <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
                Our Modules
              </h3>
              <p className="mt-3 text-md text-gray-500">
                Let’s Code aims to achieve program sustainability via its
                corporate recruitment program. Graduates from our intensive
                mentorship program are very likely to be approached and selected
                by IT and related companies to work with them as demand for
                coders in Thailand continues to outpace supply several times.
                For Let’s Code to be able to continue providing intensive
                mentorship program year after year, future funding will come
                from headhunters and sponsorship fees from private companies,
                equivalent to 3 months of starting salary or 15% of annual
                salary.
              </p>

              <dl className="mt-10 space-y-10">
                {transferFeatures.map((item) => (
                  <div key={item.id} className="relative">
                    <dt>
                      <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                        <item.icon className="h-6 w-6" aria-hidden="true" />
                      </div>
                      <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                        {item.name}
                      </p>
                    </dt>
                    <dd className="mt-2 ml-16 text-base text-gray-500">
                      {item.description}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>

            <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
              <svg
                className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                width={784}
                height={404}
                fill="none"
                viewBox="0 0 784 404"
              >
                <defs>
                  <pattern
                    id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={784}
                  height={404}
                  fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
                />
              </svg>
              <img
                className="relative mx-auto rounded-md shadow-md object-cover"
                width={490}
                src={HomeAsia}
                alt=""
              />
            </div>
          </div>

          <svg
            className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
            width={404}
            height={784}
            fill="none"
            viewBox="0 0 404 784"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={784}
              fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
            />
          </svg>
        </div>
      </div>
    );
  }
}
