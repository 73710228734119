import {
  AcademicCapIcon,
  StarIcon,
  UserGroupIcon,
  UserPlusIcon,
} from "@heroicons/react/24/outline";
import React, { Component } from "react";
import BgThai from "./assets/bg_thai.jpg";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import Mission from "./components/Mission";
import Projects from "./components/Project";

const missionFeatures: Object[] = [
  {
    name: "Beginner",
    description:
      "Elementary school students and/or general people who have no coding knowledge",
    icon: UserGroupIcon,
  },
  {
    name: "Pre-intermediate",
    description:
      "High school and university students with some basic coding knowledge",
    icon: AcademicCapIcon,
  },
  {
    name: "Intermediate",
    description:
      "High school and university students with good understanding of coding",
    icon: UserPlusIcon,
  },
  {
    name: "Advanced",
    description:
      "University students who aim to pursue their careers in coding",
    icon: StarIcon,
  },
];

export default class App extends Component {
  render() {
    return (
      <div className="relative bg-white overflow-hidden">
        <Hero
          title="Let's Code"
          titleBlue="Thailand"
          description="Let’s Code Thailand will be a partnership among APEC 2022 Thailand, the Office of National Higher Education, Science, Research and Innovation Policy Council (NXPO)’s Program Management Unit for Human Resources & Institutional Development, Research and Innovation (PMU-B), Khon Kaen University (KKU), The Asia Foundation (TAF), Digital Makers Asia Pacific (DMAP), Thai Programmers Association (TPA), and Google."
          addDescription="It aims to conduct outreach, training, and mentorship targeting 1,000 individuals from Thailand’s traditionally underserved communities. Let’s Code Thailand will provide lessons on how to engage the local developers’ community in addressing skills gap challenges. These lessons will be shared in APEC platforms, and serve as a key input for potential next phases of Let’s Code region-wise. Thus, Let’s Code Thailand will be a pilot project, a legacy initiative as Thailand’s host economy."
          image={BgThai}
        />
        <Mission
          title="Vision of Let's Code"
          description="The Let’s Code Thailand project is a platform focused on creating and improving upon programming resources with an emphasis on regional localization. In a context where the global shortage of software engineers may reach 85.2 million by 2030, emerging economies risk losing comparative advantages. Moreover, automation and other Fourth Industrial Revolution developments will require that most of the workforce understand and develop skills that complement these new technologies. Unfortunately, many current coding learning resources require some degree of English knowledge, which can pose a potential barrier for learning access. Furthermore, navigating the job market can be difficult without support systems, especially in developing economies."
          addDescription="Within the Thailand context, we seek to empower students and young adults across the economy with the necessary learning resources and support systems to navigate a changing job climate. Greater access to careers in computer science and related technology fields will ultimately improve the competitiveness of the Thai economy. Let’s Code Thailand will focus on four levels of learners"
          features={missionFeatures}
        />
        <Projects />
        <Footer />
      </div>
    );
  }
}
