import React, { Component } from "react";
import packageJson from "../../package.json";

export default class Footer extends Component {
  render() {
    return (
      <footer aria-labelledby="footer-heading">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="mt-8 border-t border-gray-200 py-4 pb-8 md:flex md:items-center md:justify-between">
            <div className="flex space-x-6 md:order-2"></div>
            <p className="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
              &copy; 2022 Digital Makers Asia Pacific Organization. All rights
              reserved. V-{packageJson.version}
            </p>
          </div>
        </div>
      </footer>
    );
  }
}
